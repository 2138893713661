<template>
  <div>
    <el-dialog :close-on-click-modal="false" :title="title" :visible="showDialog" :width="width + 'px'" v-dialog-drag
      class="center-dialog" :before-close="handleBeforeClose" :modal='modal' append-to-body>
      <div>
        <slot name="content"></slot>
      </div>
      <span slot="footer">
        <slot name="buttonList"></slot>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    // 显示弹框
    showDialog: {
      type: Boolean,
      default: false
    },
    // 弹框标题
    title: {
      type: String,
      default: ''
    },
    // 弹框宽度
    width: {
      type: Number || String,
      default: 600
    },
    levelType: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: null // 默认值为null，表示没有传入自定义函数
    }
  },
  data() {
    return {}
  },
  methods: {
    handleBeforeClose(done) {
      // 优先使用传入的beforeClose函数
      const customBeforeClose = this.beforeClose || this.defaultBeforeClose;
      customBeforeClose(done);
    },
    defaultBeforeClose(done) {
      this.$confirm('确认关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(_ => {
          if (!this.levelType) {
            this.$emit('update:showDialog', false);
          } else {
            this.$emit('close', false);
          }
          done(); // 确保调用done回调
        })
        .catch(_ => {
          // 用户点击了取消按钮，不执行关闭操作
        });
    },
  }
}
</script>

<style lang="scss" scoped>
//::v-deep .el-dialog__wrapper {
//  padding-top: 150px;
//}
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}

::v-deep .el-dialog__footer {
  padding: 20px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .el-dialog__header {
  padding: 20px;
  height: 60px;
}

::v-deep .el-dialog__header {
  border-bottom: 0.5px solid #dcdcdc;
}

::v-deep .el-dialog__footer {
  border-top: 0.5px solid #dedede;
  padding: 10px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-dialog .el-dialog {
  margin: 0 auto;
}
</style>
