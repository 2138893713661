var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    _vm._b(
      { ref: "value", attrs: { model: _vm.value } },
      "el-form",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.newOptions, function (item) {
      return _c(
        "el-form-item",
        {
          key: item.model,
          attrs: { label: item.label, rules: item.rules, prop: item.model },
        },
        [
          item.type === "input"
            ? _c("el-input", {
                style: "width: " + (item.style ? item.style.width : "") + "px",
                attrs: {
                  size: "mini",
                  psasdsad: "item.label",
                  disabled: item.disabled,
                  placeholder: item.tip || "请输入" + item.label,
                  readonly: item.readonly || false,
                  maxlength: item.maxlength,
                  type: item.inputType || "text",
                },
                on: {
                  change: function (v) {
                    return typeof item.change === "function"
                      ? typeof item.change(v)
                      : undefined
                  },
                  input: function ($event) {
                    return _vm.handleInput(
                      $event,
                      item.inputType || "text",
                      _vm.value,
                      item.model,
                      item.intMax,
                      item.dotMax
                    )
                  },
                },
                nativeOn: {
                  keydown: function ($event) {
                    return (function (v) {
                      return typeof item.keydown === "function"
                        ? typeof item.keydown(v)
                        : undefined
                    })($event)
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "checkbox-one"
            ? _vm._l(item.option.data, function (check, i) {
                return _c(
                  "el-checkbox",
                  {
                    key: i,
                    staticClass: "checkbox-one",
                    attrs: {
                      "true-label":
                        check[item.option.value || "value"] === 0
                          ? "0"
                          : check[item.option.value || "value"],
                      "false-label": "",
                      checked:
                        _vm.value[item.model] === 0
                          ? String(check[item.option.value || "value"]) === "0"
                          : check[item.option.value || "value"] ===
                            _vm.value[item.model],
                    },
                    on: {
                      change: function (e) {
                        return _vm.checkboxOne(e, item)
                      },
                    },
                    model: {
                      value: _vm.checkboxOneObj[item.model],
                      callback: function ($$v) {
                        _vm.$set(_vm.checkboxOneObj, item.model, $$v)
                      },
                      expression: "checkboxOneObj[item.model]",
                    },
                  },
                  [_vm._v(_vm._s(check[item.option.label || "label"]) + " ")]
                )
              })
            : item.type === "checkbox-more"
            ? _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.option.data, function (check, i) {
                  return _c(
                    "el-checkbox",
                    {
                      key: i,
                      attrs: { label: check[item.option.value || "value"] },
                    },
                    [_vm._v(_vm._s(check[item.option.label || "label"]) + " ")]
                  )
                }),
                1
              )
            : item.type === "radio-button"
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "mini", disabled: item.disabled },
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.optionData, function (optionItem, i) {
                  return _c(
                    "el-radio-button",
                    {
                      key: optionItem.value + i,
                      attrs: { label: optionItem.value },
                    },
                    [_vm._v(_vm._s(optionItem.label) + " ")]
                  )
                }),
                1
              )
            : item.type === "radio"
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.optionData, function (radio, i) {
                  return _c(
                    "el-radio",
                    {
                      key: radio.value + "." + i,
                      attrs: { label: radio.value },
                    },
                    [_vm._v(_vm._s(radio.label) + " ")]
                  )
                }),
                1
              )
            : item.type === "text-append"
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "36px",
                    "line-height": "36px",
                    padding: "0 15px",
                    "font-size": "14px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "font-size": "28px", color: "red" } },
                    [_vm._v(_vm._s(_vm.value[item.model]))]
                  ),
                ]
              )
            : item.type === "date-picker"
            ? _c("el-date-picker", {
                attrs: {
                  size: "mini",
                  type: "datetimerange",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOptions,
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "number"
            ? _c("el-input-number", {
                staticClass: "number-view",
                class: { inputNumber: item.option.disabled },
                style:
                  "width: " +
                  (item.option.style ? item.option.style.width : 140) +
                  "px;",
                attrs: {
                  disabled: item.option.disabled,
                  size: "mini",
                  min: item.option.min,
                  max: item.option.max,
                  controls: item.option.controls,
                  precision: item.option.precision || 0,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "input-number"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "flex-view" },
                  [
                    _c("el-input-number", {
                      class: { inputNumber: item.disabled },
                      style:
                        "width: " +
                        (item.style ? item.style.width : 140) +
                        "px",
                      attrs: {
                        disabled: item.disabled,
                        size: "mini",
                        min: item.min,
                        max: item.max,
                        precision: item.precision || 0,
                      },
                      model: {
                        value: _vm.value[item.model],
                        callback: function ($$v) {
                          _vm.$set(_vm.value, item.model, $$v)
                        },
                        expression: "value[item.model]",
                      },
                    }),
                    item.append && item.append.type === "xyt-users"
                      ? _c("div", { staticClass: "append-view" }, [
                          item.append.getPrice() &&
                          item.append.getPrice().orderMoney
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#f44417",
                                    "font-size": "18px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Number(
                                          item.append.getPrice().orderMoney
                                        ).toFixed(2)
                                      ) +
                                      "元/" +
                                      _vm._s(
                                        item.append
                                          .getPrice()
                                          .unit.replace("一年", "年")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.append.getPrice() &&
                          item.append.getPrice().oldOrderMoney
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#999999",
                                    "font-size": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 原价" +
                                      _vm._s(
                                        item.append
                                          .getPrice()
                                          .oldOrderMoney.toFixed(2)
                                      ) +
                                      "元/" +
                                      _vm._s(
                                        item.append
                                          .getPrice()
                                          .unit.replace("一年", "年")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.append.getPrice() &&
                          +item.append.getPrice().discounted >= 0
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#999999",
                                    "font-size": "12px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 折扣价" +
                                      _vm._s(
                                        item.append.getPrice().discounted
                                      ) +
                                      "元 "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                item.append &&
                item.append.type === "xyt-users" &&
                item.append.show
                  ? _c("div", [
                      item.model === "userNumber"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#999999",
                                "font-size": "16px",
                              },
                            },
                            [
                              item.initializeUsers > 0 && item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      "含" +
                                        _vm._s(item.initializeUsers) +
                                        "个初始用户"
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializeUsersPrice > 0 &&
                              item.initializeUsers > 0 &&
                              item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          item.initializeUsers *
                                          item.initializeUsersPrice
                                        ).toFixed(2)
                                      ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializeUsersPrice > 0 &&
                              item.initializeUsers > 0 &&
                              item.append.getPrice().showUsersPrice &&
                              item.append.isInitial
                                ? _c("span", [_vm._v("，")])
                                : _vm._e(),
                              item.append.getPrice().showUsersPrice
                                ? _c("span", [
                                    _vm._v(
                                      "每增加1用户收费￥" +
                                        _vm._s(
                                          item.append.getPrice().showUsersPrice
                                        ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      item.model === "shopNumber"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#999999",
                                "font-size": "16px",
                              },
                            },
                            [
                              item.initializeShops > 0 && item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      "含" +
                                        _vm._s(item.initializeShops) +
                                        "个初始门店"
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializeShopsPrice > 0 &&
                              item.initializeShops > 0 &&
                              item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          item.initializeShops *
                                          item.initializeShopsPrice
                                        ).toFixed(2)
                                      ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializeShopsPrice > 0 &&
                              item.initializeShops > 0 &&
                              item.append.getPrice().showShopPrice &&
                              item.append.isInitial
                                ? _c("span", [_vm._v("，")])
                                : _vm._e(),
                              item.append.getPrice().showShopPrice
                                ? _c("span", [
                                    _vm._v(
                                      "每增加1门店收费￥" +
                                        _vm._s(
                                          item.append.getPrice().showShopPrice
                                        ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      item.model === "possNumber"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#999999",
                                "font-size": "16px",
                              },
                            },
                            [
                              item.initializePoss > 0 && item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      "含" +
                                        _vm._s(item.initializePoss) +
                                        "个初始站点"
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializePossPrice > 0 &&
                              item.initializePoss > 0 &&
                              item.append.isInitial
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (
                                          item.initializePoss *
                                          item.initializePossPrice
                                        ).toFixed(2)
                                      ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              item.initializePossPrice > 0 &&
                              item.initializePoss > 0 &&
                              item.append.getPrice().showPossPrice &&
                              item.append.isInitial
                                ? _c("span", [_vm._v("，")])
                                : _vm._e(),
                              item.append.getPrice().showPossPrice
                                ? _c("span", [
                                    _vm._v(
                                      "每增加1站点收费￥" +
                                        _vm._s(
                                          item.append.getPrice().showPossPrice
                                        ) +
                                        "元/" +
                                        _vm._s(
                                          item.append
                                            .getPrice()
                                            .unit.replace("一年", "年")
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : item.type === "table"
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      ref: item.ref,
                      refInFor: true,
                      staticStyle: { width: "746px" },
                      attrs: { border: "", data: item.option.tableData },
                      on: {
                        "selection-change": item.option.handleSelectionChange,
                      },
                    },
                    [
                      !item.eltableCheck
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "selection",
                              width: "55",
                            },
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "序号",
                          type: "index",
                          width: "55",
                        },
                      }),
                      _vm._l(item.option.tableHand, function (tab, tabIndex) {
                        return _c("el-table-column", {
                          key: tabIndex,
                          attrs: {
                            align: "center",
                            prop: tab.prop,
                            label: tab.label,
                            width: tab.width,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : item.type === "tel"
            ? _c("el-input", {
                style: "width: " + (item.style ? item.style.width : "") + "px",
                attrs: {
                  size: "mini",
                  disabled: item.disabled,
                  type: "number",
                  placeholder: item.tip || "请输入" + item.label,
                },
                on: {
                  input: function (v) {
                    return _vm.changeNumber(v, item.model)
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "divider"
            ? _c("el-divider")
            : item.type === "upload-one"
            ? _c("OssUpload", {
                style: "width: " + (item.style ? item.style.width : "") + "px",
                attrs: { option: item.option },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "textarea"
            ? _c("el-input", {
                attrs: {
                  size: "mini",
                  disabled: item.disabled,
                  type: "textarea",
                  rows: 2,
                  autosize: { minRows: 2, maxRows: 100 },
                  placeholder: "请输入内容",
                  maxlength: item.maxlength,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "remote"
            ? _c("SelectRemote", {
                attrs: {
                  size: "mini",
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  placeholder: item.placeholder || "选择" + item.label,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "tree"
            ? _c("SelectTree", {
                attrs: {
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  placeholder: item.placeholder || "选择" + item.label,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "local"
            ? _c("SelectLocal", {
                style: { width: item.width },
                attrs: {
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  placeholder: item.placeholder || "选择" + item.label,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "switch"
            ? _c("el-switch", {
                attrs: {
                  size: "mini",
                  "active-value": item.open,
                  "inactive-value": item.close,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "cascader"
            ? _c("el-cascader", {
                style:
                  "width: " + (item.style ? item.style.width + "px" : "100%"),
                attrs: {
                  size: "mini",
                  placeholder: item.placeholder || "选择" + item.label,
                  options: item.option ? item.option.data : [],
                  clearable: item.option.clearable || false,
                  filterable: item.option.filterable || false,
                  value: item.option.value,
                  label: item.option.label,
                  props: {
                    value: item.option.value,
                    label: item.option.label,
                    emitPath: item.option.emitPath,
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "editor"
            ? _c(
                "div",
                [
                  _c("el-upload", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: _vm.uploadUrl,
                      "show-file-list": false,
                      "on-success": _vm.uploadSuccess,
                      "before-upload": _vm.beforeUpload,
                    },
                  }),
                  _c("quill-editor", {
                    ref: "QuillEditor",
                    refInFor: true,
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.value[item.model],
                      callback: function ($$v) {
                        _vm.$set(_vm.value, item.model, $$v)
                      },
                      expression: "value[item.model]",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }